body {
  margin: 0;
  font-family: "Figtree", "Inter", sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p {
  font-weight: 400;
  font-size: 18px;
  line-height: 1.6;
}

h1 {
  font-family: "Yeseva One", serif;
  font-weight: 400;
  font-size: 50px;
}

h2 {
  font-family: "Ibarra Real Nova", serif;
  font-weight: 700;
  font-size: 30px;
}

h3 {
  font-family: "Ibarra Real Nova", serif;
  font-weight: 700;
}

a.highlight,
.fe-page-object {
  color: inherit;
  text-decoration: none;
  background-image: linear-gradient(
    180deg,
    transparent 0,
    var(--description-color) 0
  );
  background-repeat: no-repeat;
  background-position: 0 1.125rem;
  transition: background-position 0.08s ease-out;
}

a.highlight:hover,
.fe-page-object:hover {
  background-position: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* background gradients/colors */

:root {
  --main-color: #352c50;
  --main-gradient: url("./assets/backgrounds/gradient-background.PNG");
  --gender-gradient: url("./assets/backgrounds/gender.PNG");
  /* --gender-gradient: url("./assets/backgrounds/gender.jpeg"); */
  --relationships-gradient: url("./assets/backgrounds/relationships.PNG");
  --sentiment-gradient: url("./assets/backgrounds/sentiment.PNG");
  --script-gradient: url("./assets/backgrounds/script.png");
  --character-gradient: url("./assets/backgrounds/character.PNG");
  --box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px 0px;
  --font-ibarra: "Ibarra Real Nova", serif;
}

.mobileBanner {
  height: 100vh;
  width: 100vw;
  margin: 0;
  background: var(--main-gradient);
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobileBanner h1 {
  margin: 0;
  text-align: center;
  padding: 0 30px;
  color: white;
}

.error__page-content {
  height: 100vh;
  width: 100vw;
  background-image: var(--main-gradient);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.error__page-content h1 {
  margin: 0;
  margin-bottom: 50px;
}

.error__page-content a {
  color: var(--main-color);
  font-size: 20px;
}
