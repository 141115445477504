.menu {
  width: 100vw;
  height: 100vh;
  position: fixed;
  transition: opacity 0.5s;
  /* transition: top 0.5s; */
  top: 0;
  background-color: gray;
  z-index: 6;

  display: flex;
}

.menu.hide {
  opacity: 0;
  pointer-events: none;
}

.menu__left {
  flex: 0 0 52%;
  background-image: var(--main-gradient);
  background-repeat: no-repeat;
  min-height: 100%;
  background-position: center;
  background-size: cover;

  padding: 100px 100px 0 70px;
  /* color: white; */
  color: #0b0a12;

  overflow-y: scroll;
}

.menu__left img {
  width: 100%;
}

.menu__left p {
  font-size: 18px;
  margin-bottom: 80px;
}

.menu__left .menu__button {
  box-sizing: border-box;
  display: flex block;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* display: block; */
  width: 220px;
  height: 50px;
  /* padding: 15px 0; */
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.3s;
}

.menu__left .menu__button-outline {
  border: 1px solid white;
  background: rgba(0, 0, 0, 0.1);
  color: rgba(255, 255, 255, 0.8);
}

.menu__left .menu__button:hover {
  border-width: 2px;
}

.menu__left .menu__button-outline:hover {
  color: white;
}

.menu__left .menu__button-solid {
  margin-top: 12px;
  background-color: white;
  text-decoration: none;
  color: black;
  border: none;
}

.menu__right {
  background: var(--main-color);
  flex: 0 0 48%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  padding-left: 40px;
  overflow-y: scroll;
}

.menu__link-large {
  font-size: 20px;
  color: white !important;
  display: block;
  text-decoration: underline;
  text-underline-offset: 6px;
  margin-bottom: 20px;
  opacity: 0.7;
  transition: all 0.5s;
}

.menu__link-large:hover {
  opacity: 1;
}

.menu__right h2 {
  margin-top: 50px;
  font-weight: 500;
  font-size: 20px;
  color: white;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.menu__link-collection a {
  display: inline-block;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 7px;
  text-decoration: none;
}

.fe-page-object {
  font-weight: 600;
}

@media (max-width: 900px) {
  .menu__left {
    padding: 100px 50px 0;
  }

  .menu__left p {
    margin-bottom: 40px;
  }
}
