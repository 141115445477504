.home {
  background-image: var(--main-gradient);
  background-repeat: no-repeat;
  min-height: 100%;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

.home-banner {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}

.home-banner_sprites {
  width: 80%;
  height: auto;
  max-width: 1000px;
}

.home-banner_logo {
  width: 90%;
  max-width: 800px;
  height: auto;
}

.home p {
  margin: 0;
}

.home-title {
  margin-top: -50px;
  margin-bottom: 20px;
  font-size: 70px;
  font-weight: 600;
  text-transform: uppercase;
  max-width: 750px;
}

.home-subtitle {
  font-weight: 300;
  margin-top: 0;
  font-size: 38px;
}

.home-banner-v2 {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  /* text-align: center; */
}

.home-banner-v2_sprites {
  width: 80%;
  max-width: 1000px;
}

.home-banner-v2_logo {
  width: 80%;
  max-width: 1000px;
  height: auto;
  align-self: flex-start;
  margin-left: 50px;
}

.home-v2-title {
  width: 80%;
  max-width: 1000px;
  text-align: left;
  margin-top: -50px;
  margin-bottom: 20px;
  font-size: 70px;
  font-weight: 600;
  text-transform: uppercase;
}

.home-v2-subtitle {
  width: 80%;
  max-width: 1000px;
  font-weight: 300;
  margin-top: 0;
  font-size: 38px;
}

.home-banner-v3 {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #0b0a12;
  /* color: white; */
  /* color: var(--main-color); */
  /* text-align: center; */
}

.home-banner-v3_sprites {
  width: 80%;
  max-width: 1000px;
}

.home-banner-v3_logo {
  width: 80%;
  max-width: 1000px;
  height: auto;
  align-self: flex-start;
  margin-left: 50px;
}

.home-v3-title {
  width: 80%;
  max-width: 1200px;
  text-align: center;
  margin: 0;
  /* margin-top: -50px; */
  /* margin-bottom: 20px; */
  font-size: 100px;
  /* font-weight: 600; */
  text-transform: uppercase;
  font-family: "DM Serif Display", serif;
}

.home-v3-title-alt {
  width: 90%;
  max-width: 1200px;
  text-align: center;
  margin: 0;
  margin-top: -50px;
  margin-bottom: 40px;
  font-size: 120px;
  /* font-weight: 600; */
  font-family: "Silkscreen", sans-serif;
  text-transform: uppercase;
}

.home-v3-subtitle {
  width: 80%;
  text-align: center;
  max-width: 1000px;
  font-weight: 300;
  margin-top: 0;
  font-size: 38px;
}

.article-grid__item {
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  /* box-shadow: var(--box-shadow); */
  margin: 0 10px;
  background: rgba(255, 255, 255, 0.75);
}

.article-grid__item:hover {
  box-sizing: border-box;
  box-shadow: var(--box-shadow);
  margin: 0 10px;
  background: rgba(255, 255, 255, 1);
  transition: all 0.3s;
  border: 4px solid #d6cabc;
}

.article-grid__item a {
  text-decoration: none;
  /* color: white; */
  color: black;
  height: 100%;
}

.article-grid__item img {
  max-width: 100%;
}

.article-grid__item h6 {
  font-weight: 700;
  font-size: 25px;
  font-family: var(--font-ibarra);
  margin: 10px 0;
}

.article-grid__item p {
  font-size: 16px;
  text-align: center;
  margin: 0 10px 10px;
}

@media (max-width: 1240px) {
  .home-v3-title {
    font-size: 90px;
  }

  .home-v3-title-alt {
    font-size: 100px;
  }
}

@media (max-width: 1080px) {
  .home-v3-title {
    text-transform: none;
    font-size: 90px;
  }

  .home-v3-title-alt {
    font-size: 80px;
  }

  .home-v3-subtitle {
    font-size: 30px;
  }
}
