.dev-article {
  background-image: var(--script-gradient);
  background-repeat: no-repeat;
  min-height: 100%;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  /* background: #eee; */
  padding: 100px 0 50px;
  /* padding: 100px 20px 50px; */

  color: #1d2034;

  --description-color: #709ae6;
}

.dev-intro {
  margin: 0 80px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dev-intro.banner {
  min-height: 70vh;
  text-align: justify;
}

.dev-intro.banner p {
  text-align: justify;
}

.dev-intro h1 {
  padding: 0 20px;
  margin: 50px 0 0px;
  text-align: center;
}

.dev-intro-paragraphs {
  max-width: 70vw;
  margin-top: 50px;
}

.dev-intro-paragraphs li {
  margin-bottom: 10px;
  font-size: 18px;
}

.dev-timeline {
  /* border: 1px solid red; */
  margin-bottom: 200px;
  /* margin */
}

.dev-timeline-intro {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 0 80px;
}

.dev-timeline-intro h2,
.dev-timeline-intro p {
  max-width: 70vw;
}

.dev-scroll-container {
  margin: 20vh 4vw 20vh;
  display: flex;
  justify-content: space-between;
}

.dev-scroll-graphic {
  flex-basis: 40%;
  position: sticky;
  width: 100%;
  height: 60vh;
  top: 18vh;
  /* background-color: #aaa; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.dev-scroll-graphic p {
  font-size: 5rem;
  font-weight: 700;
  text-align: center;
  color: #fff;
}

.dev-scroller {
  flex-basis: 55%;
}

.dev-scroller-step {
  margin: 0 auto 3rem auto;
  padding: 80px 0;
  /* border: 1px solid #333; */
}

.dev-scroller-step p {
  text-align: left;
  padding: 1rem;
  font-size: 20px;
  line-height: 1.8;
  /* font-size: 1.8rem; */
  margin: 0;
}

.dev-scroller-step:last-child {
  margin-bottom: 0;
}

.dev-tapewrapper {
  max-width: 80vw;
  z-index: 5;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  /* text-align: center; */
  line-height: 2;
}

.dev-tapewrapper span {
  padding: 8px 10px 11px;
  box-shadow: 15px 0 transparent, -15px 0 transparent;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background-color: rgba(235, 240, 246, 0.8);
  font-size: 18px;
  /* font-weight: 500; */
  /* text-align: left; */
}

.dev-tapewrapper.addendum {
  margin-top: 20px;
  z-index: 0;
}

.dev-scroll-graphic img {
  width: 100%;
  /* height: 100%; */
  object-fit: contain;
  transition: all 1s;
  border: 5px solid white;
  box-shadow: var(--box-shadow);
}

.dev-scroller-step-quote {
  font-style: italic;
}

.dev-timeline-bar-container {
  position: sticky;
  top: 20px;
  height: 0px;
  display: flex;
  justify-content: center;
  z-index: 5;
  transition: opacity 0.5s;
}

.dev-timeline-categories {
  position: absolute;
  /* border: 1px solid red; */
  top: 30px;
  display: flex;
  width: 750px;
  justify-content: space-between;
}

.dev-timeline-categories span {
  font-size: 16px !important;
}

.dev-timeline-categories .dev-scroller-category {
  padding: 5px 8px;
}

.dev-timeline-bar-container progress {
  position: relative;
  top: 20px;
  width: 80vw;
  height: 8px;
  transition: top 0.5s;
  z-index: 5;
  -webkit-appearance: none;
}

progress::-webkit-progress-bar {
  -webkit-appearance: none;
  background-color: #bbb;
  border-radius: 100px;
}

.dev-timeline-progress-update {
  top: -15vh !important;
  transition: top 0.5s;
}

progress::-webkit-progress-value {
  transition: all 0.5s;
  border-radius: 100px;
}

progress.NES::-webkit-progress-value,
.dev-timeline-dot.NES {
  background-color: #6f1f2f;
}

progress.SNES::-webkit-progress-value,
.dev-timeline-dot.SNES {
  background-color: #a1683b;
}

progress.GBA::-webkit-progress-value,
.dev-timeline-dot.GBA {
  background-color: #dcbb28;
}

progress.Wii::-webkit-progress-value,
.dev-timeline-dot.Wii {
  background-color: #77a04a;
}

progress.DS::-webkit-progress-value,
.dev-timeline-dot.DS {
  background-color: #417d8f;
}

progress.TDS::-webkit-progress-value,
.dev-timeline-dot.TDS {
  background-color: #4c4b94;
}

progress.Switch::-webkit-progress-value,
.dev-timeline-dot.Switch {
  background-color: #734b89;
}

.dev-timeline-point {
  position: absolute;
  top: -29px;
  height: 0px;
  transition: all 0.5s;
  z-index: 5;
}

.dev-timeline-point p {
  margin-bottom: 12px;
}

.dev-timeline-dot {
  height: 15px;
  width: 15px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  top: -15px;
  transition: all 0.5s;
  z-index: 5;
  transition: background-color 0.5s;
}

.dev-timeline-background {
  position: absolute;
  width: 100%;
  height: 80px;
  top: -20px;
  z-index: 2;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.9) 45%,
    rgba(255, 255, 255, 0) 100%
  );
  /* background-color: white; */
}

span.dev-scroller-category {
  border: 2px solid black;
  transition: 0.125s all linear;
  background-color: #fff;
  border-radius: 0.25rem;
  padding: 0.125rem 0.5rem;
  -webkit-box-decoration-break: clone;
  box-shadow: -2px 2px 5px #00000020;
  opacity: 1;
}

span.dev-scroller-category.cat-one {
  border-color: #ffb7c3;
}

span.dev-scroller-category.cat-one:hover {
  background-color: #ffb7c3;
}

span.dev-scroller-category.cat-two {
  border-color: #fbc258;
}

span.dev-scroller-category.cat-two:hover {
  background-color: #fbc258;
}

span.dev-scroller-category.cat-three {
  border-color: #bcdf83;
}

span.dev-scroller-category.cat-three:hover {
  background-color: #bcdf83;
}

.dev-section-name {
  position: fixed;
  right: 0px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  transition: all 0.5s;
  width: 40vw;
  padding: 30px 30px 15px 70px;
  /* background-image: radial-gradient(
    farthest-corner at 300px 300px,
    white 0%,
    transparent 75%
  ); */
  text-align: right;
  pointer-events: none;
}

.dev-section-name img {
  width: 300px;
  max-height: 200px;
  object-fit: contain;
  object-position: right;
}

.dev-section-name .dev-title-tapewrapper {
  font-size: 25px;
  font-family: "Ibarra Real Nova", serif;
  font-weight: 700;
  color: #1d2034;
}

.dev-section-name .dev-title-tapewrapper.addendum {
  padding-bottom: 20px;
}

.dev-title-tapewrapper span {
  padding: 8px 10px 11px;
  box-shadow: 15px 0 transparent, -15px 0 transparent;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  background-color: rgba(249, 252, 255, 0.8);
  text-transform: uppercase;
}

.dev-title-tapewrapper.addendum span {
  text-transform: none;
}
