/* FLEX ITEMS */

.flex-legend {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
  align-items: center;
}

.flex-legend-item {
  display: flex;
  align-items: center;
  font-size: 12px;
  margin: 15px 15px 5px;
  color: black;
  font-weight: 500;
}

.flex-legend-item-square {
  width: 10px;
  height: 10px;
  color: inherit;
  background-color: currentColor;
  margin-right: 5px;
}

.emotion-label-text {
  cursor: pointer;
}

/* .emotion-label-text {
  stroke: white;
  stroke-width: 0.5em;
  fill: black;
  paint-order: stroke;
  stroke-linejoin: round;
  z-index: 10;
} */

.emotion-game__label {
  text-align: left;
  width: 100%;
  margin-top: 10px;
  max-width: 75vw;
  border-bottom: 1px solid black;
  margin-bottom: 30px;
}

.emotion-game__label h2 {
  font-size: 20px;
}

.emotion-game__top-shelf {
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  width: 100%;
}

.emotion-game__bottom-shelf {
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  /* width: 100%; */
  display: flex;
  justify-content: flex-end;
  padding: 0 20vw;
}

/* path.slice{
	stroke-width:2px;
} */

polyline {
  opacity: 0.3;
  stroke: black;
  stroke-width: 2px;
  fill: none;
}

.emotion-chapter-selects {
  display: flex;
}

.emotion-chapter-selects span {
  margin-right: 25px;
}

.emotion-chapter-selects select {
  margin-bottom: 5px;
}

.select-button {
  padding: 10px 20px;
  border: none;
  color: #172e2c;
  border-radius: 10px;
  margin: 10px 0 20px;
  font-size: 15px;
  border-width: 1px;
  font-weight: 500;
  cursor: pointer;
}

.select-button:disabled {
  color: rgba(23, 46, 44, 0.4);
}

.story-progression-container {
  width: 900px;
}

@media (max-width: 900px) {
  .story-progression-container {
    width: 650px;
  }
}
