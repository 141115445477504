/* .genderlinecounts {
    background: 
} */

.script-page {
  background-image: var(--script-gradient);
  background-repeat: no-repeat;
  min-height: 100%;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;

  padding: 80px 40px;

  display: flex;
  flex-direction: column;
  align-items: center;

  --description-color: #709ae6;
  --highlight-color: #030321;
}

.script-page h1 {
  color: #3b2708;
  margin-bottom: 5px;
}
