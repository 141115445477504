.viz {
  background-image: var(--main-gradient);
  background-repeat: no-repeat;
  min-height: 100%;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  color: white;
  text-align: center;
  padding: 50px 30px;
}

.viz h2 {
  font-size: 55px;
  margin-bottom: 0;
  font-weight: 550;
}

.viz h3 {
  font-weight: 400;
  font-size: 20px;
  font-family: "Figtree", sans-serif;
}

.viz__button {
  background-color: white;
  padding: 10px 20px;
  border-radius: 20px;
  color: rgba(253, 137, 91, 0.8);
  text-decoration: none;
  font-weight: 500;
  font-size: 18px;
}

.viz__highlight {
  color: var(--highlight-color);
  font-weight: 600;
}
