body {
  --description-color: red;
}

.description__container {
  display: inline-block;
  position: relative;
  /* font-weight: 600; */
  /* text-decoration: underline;
  text-underline-offset: 3px;
  text-decoration-color: #3d6fe3; */
  /* border-bottom: 1px dotted #666; */
  text-align: left;
  cursor: pointer;
}

.description__container span {
  padding: 0px !important;
}

.description__container span {
  background-image: linear-gradient(
    180deg,
    transparent 0,
    var(--description-color) 0
  );
  background-repeat: no-repeat;
  background-position: 0 1.15rem;
  transition: background-position 0.08s ease-out;
}

.description__container span:hover {
  background-position: 0;
}

.description__container .description__hover-box {
  min-width: 200px;
  top: 40px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 10px 20px 10px;
  color: #000000;
  background-color: #ffffff;
  font-weight: normal;
  font-size: 13px;
  border-radius: 8px;
  position: absolute;
  z-index: 9;
  box-sizing: border-box;
  box-shadow: var(--box-shadow);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.8s;
  line-height: 1.4 !important;
}

.description__container:hover .description__hover-box {
  visibility: visible;
  opacity: 1;
}

.description__container .description__hover-box i {
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -12px;
  width: 24px;
  height: 12px;
  overflow: hidden;
}

.description__container .description__hover-box i::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 50%;
  transform: translate(-50%, 50%) rotate(45deg);
  background-color: #ffffff;
  box-shadow: var(--box-shadow);
}

.description__hover-box h3 {
  font-size: 20px !important;
  font-weight: 700;
  margin-bottom: 8px;
  color: #10258f;
}

.description__hover-box p {
  font-size: 15px !important;
  padding: 0px !important;
  padding-bottom: 15px !important;
  line-height: 1.4 !important;
  color: #000000;
}
