/* .genderlinecounts {
    background: 
} */

.relationship-page {
  background-image: var(--relationships-gradient);
  background-repeat: no-repeat;
  min-height: 100%;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;

  padding: 80px 40px;

  display: flex;
  flex-direction: column;
  align-items: center;

  --description-color: #385f83;
  --highlight-color: #b6c5fc;
}

.relationship-page h1 {
  color: #c6d0d8;
  margin-bottom: 5px;
}

.relationship-page p {
  color: #c6d0d8;
}

.checkbox-label {
  display: flex;
  align-items: center;
  margin: 10px 20px;
}

.checkbox-label input {
  margin-right: 5px;
}

.networks-checkbox {
  color: #c6d0d8;
}
