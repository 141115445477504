.viz__container-outer {
  margin: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.viz-grid__container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  /* margin: 50px 0; */
  width: 100%;
  max-width: 1300px;
}

.viz-grid__item {
  padding: 20px;
  box-sizing: border-box;
  transition: all 0.3s;
}

.viz-grid__item:hover {
  /* border: 1px solid #0b0a12; */
  box-shadow: var(--box-shadow);
  background-color: rgba(240, 235, 235, 0.7);
  /* border: 1px solid white; */
  border-radius: 10px;
}

.viz-grid__item a {
  text-decoration: none;
  /* color: white; */
  color: #0b0a12;
  height: 100%;
}

.viz-grid__item img {
  max-width: 100%;
}

.viz-grid__item h6 {
  font-weight: 450;
  font-size: 16px;
  margin: 0;
  margin-top: 10px;
}
