/* .genderlinecounts {
    background: 
} */

.gender-page {
  background-image: var(--gender-gradient);
  background-repeat: no-repeat;
  min-height: 100%;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;

  padding: 80px 40px;

  display: flex;
  flex-direction: column;
  align-items: center;

  --description-color: #c88eed;
  --highlight-color: #ad035b;
}

.gender-page h1 {
  color: #3b2708;
  margin-bottom: 5px;
}

/* ARTICLE STYLES! */

.article p {
  max-width: 70vw;
  margin-bottom: 30px;
}

.tooltip {
  position: absolute;
  top: 0;
  background-color: white;
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  padding: 20px 20px 20px;
  box-sizing: border-box;
  line-height: 1.4 !important;
  font-weight: normal;
  font-size: 13px;
}

.tooltip h2,
.tooltip h4 {
  font-family: var(--font-ibarra);
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
  margin-top: 0;
  color: #10258f;
}

.tooltip h5 {
  font-family: var(--font-ibarra);
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
  color: #10258f;
}

.genderlinecounts-tooltip h5 {
  color: #873363;
}

.gendercharcounts-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.gendercharcounts-leftchart {
  flex: 45%;
  display: flex;
  flex-direction: column;
}

@media (max-width: 900px) {
  .gendercharcounts-container {
    flex-direction: column;
  }
}

.select-dropdown {
  width: 200px;
  padding: 5px 8px;
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(115, 115, 115, 0.5);
  border-radius: 3px;
  font-family: "Figtree", sans-serif;
}
