.article__container {
  margin: 50px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.article__item {
  display: block;
  width: 100%;
  max-width: 1200px;
  /* height: 200px; */

  display: flex;
  flex-direction: row;
  align-items: flex-start;

  padding: 30px 20px;
  box-sizing: border-box;
  border-radius: 10px;
  /* box-shadow: var(--box-shadow); */
  margin: 10px 0 20px;
  background: rgba(255, 255, 255, 0.7);
  text-decoration: none;
}

.article__item:hover {
  box-sizing: border-box;
  box-shadow: var(--box-shadow);
  /* margin: 0 10px; */
  background: rgba(255, 255, 255, 1);
  transition: all 0.3s;
  /* border: 4px solid #d6cabc; */
}

.article__item img {
  width: 30%;
}

.article__info {
  /* color: white; */
  color: black !important;
  height: 100%;
  padding: 15px 30px;
  text-align: left;
}

.article__info h6 {
  font-weight: 700;
  font-size: 32px;
  font-family: var(--font-ibarra);
  margin: 10px 0 30px;
}
