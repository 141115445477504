.click-only-text {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.click-only-text::selection {
  background: none;
}

.word-default {
  opacity: 0.8;
  /* fill: cadetblue; */
  font-weight: normal;
}
.word-hovered {
  /* fill: teal; */
  opacity: 1;
  font-weight: bold;
  transition: opacity 0.5s;
}
/* .word-selected {
  fill: darkslategrey;
  font-weight: bold;
} */

.mostcommonwords-tooltip {
  width: 350px;
  padding: 20px;
}

.mostcommonwords-tooltip p {
  margin: 0;
}

mark {
  font-size: 14px;
  padding: 1px 3px;
  /* margin: 2px; */
  line-height: 1;
  display: inline-block;
  border-radius: 2px;
  /* background: #fc3; */
}

.examples {
  position: absolute;
  top: 0;
  padding: 10px 10px 10px 10px;
  max-width: 250px;
  font-family: sans-serif;
  font-size: 13px;
  background-color: white;
  font-weight: bold;
  text-align: left;
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  line-height: 1.4 !important;
}

.examples h3 {
  margin: 10px 5px;
  font-family: var(--font-ibarra);
  color: #10258f;
  border-bottom: 1px solid black;
  text-align: left;
  font-size: 18px;
  font-weight: 700;
}

span.word {
  display: inline-block;
  padding: 0 5px;
  border-radius: 5px;
  color: black;
  margin: 2px;
}

.tooltip-title {
  font-family: var(--font-ibarra);
}

.complexity-checkboxes {
  display: flex;
  margin-bottom: 10px;
}

.similarity-container {
  width: 80vw;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
