/* .genderlinecounts {
    background: 
} */

.sentiment-page {
  background-image: var(--sentiment-gradient);
  background-repeat: no-repeat;
  min-height: 100%;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;

  padding: 80px 40px;

  display: flex;
  flex-direction: column;
  align-items: center;

  --description-color: #dab26e;
  --highlight-color: #4f320f;
}

.sentiment-page h1 {
  color: #123622;
  margin-bottom: 5px;
}

.sentiment-page p {
  color: #123622;
}

.sentiment-tooltip h5 {
  color: #244a2e;
  margin-top: 10px;
}

.sentimentacrossgames-container {
  display: flex;
}

.sentimentacrossgames-leftchart {
  flex: 45%;
}

.sentimentacrossgames-rightchart {
  flex: 65%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

@media (max-width: 1000px) {
  .sentimentacrossgames-container {
    flex-direction: column;
    align-items: center;
  }
}
