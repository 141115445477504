.navbar {
  height: 80px !important;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  position: fixed;
  top: 0;

  background: linear-gradient(
    180deg,
    rgba(253, 137, 91, 1) 0%,
    rgba(253, 137, 91, 0.9) 45%,
    rgba(252, 102, 39, 0) 100%
  );

  transition: opacity 0.5s;
  z-index: 6;
  /* transition: background 0.2s; */
}

.navbar.hide {
  opacity: 0;
  transition: opacity 0.2s;
}

.navbar a {
  text-decoration: none;
}

.navbar h4 {
  margin-left: 5px;
  font-weight: 300;
  font-family: "DM Serif Display", serif;
  line-height: 1.1;
}

.navbar h4 span.nav__differentfont {
  font-family: "Silkscreen", sans-serif;
}

.navbar h4.hide {
  opacity: 0;
  transition: opacity 0.4s;
}

.navbar__fullscreen {
  width: 100vw;
  height: 100vh;
  background-color: white;
  position: fixed;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 180px;
  transition: top 0.2s;
  top: 0;
}

.navbar__fullscreen.hide {
  top: -150vh;
  pointer-events: none;
}

.nav__logo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.nav__logo img {
  width: 45px;
  /* height: 40px; */
  cursor: pointer;
  padding-left: 15px;
}

.nav__logo img.hide {
  opacity: 0;
  transition: opacity 0.2s;
}

/* BURGER MENU */

.checkboxInput {
  display: none;
}

input + label {
  position: fixed;
  top: 30px;
  right: 30px;
  height: 20px;
  width: 15px;
  z-index: 7;
}
input + label span {
  position: absolute;
  width: 100%;
  height: 2px;
  top: 50%;
  margin-top: -1px;
  left: 0;
  display: block;
  /* background: #020304; */
  background: white;
  transition: 0.5s;
}
input + label span:first-child {
  top: 3px;
}
input + label span:last-child {
  top: 17px;
}
label:hover {
  cursor: pointer;
}
input:checked + label span {
  opacity: 0;
  top: 50%;
}
input:checked + label span:first-child {
  opacity: 1;
  transform: rotate(405deg);
}
input:checked + label span:last-child {
  opacity: 1;
  transform: rotate(-405deg);
}
