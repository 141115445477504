.home-viz {
  color: white;
  text-align: center;
  margin: 50px 30px 0;
  padding-bottom: 100px;
}

.home-viz h2 {
  font-size: 55px;
  margin-bottom: 0;
  font-weight: 550;
}

.home-viz h3 {
  font-weight: 400;
  font-size: 20px;
  font-family: "Figtree", sans-serif;
}

.home-viz__button {
  background-color: rgba(255, 255, 255, 0.8);
  /* background-color: white; */
  padding: 10px 20px;
  border-radius: 20px;
  color: #0b0a12;
  /* color: rgba(253, 137, 91, 0.8); */
  text-decoration: none;
  font-weight: 500;
  /* font-size: 18px; */
  font-size: 16px;
}
.home-viz__button:hover {
  background-color: white;
  font-weight: bold;
}
