.genderlinecounts-tooltip {
  font-size: 14px;
}

.genderlinecounts-tooltip h5 {
  margin: 0;
  font-size: 18px;
}

.classes-timeline-text {
  font-size: 12.5px;
}