footer {
  display: flex;
  /* border: 1px solid black; */
  background: var(--main-color);
  padding: 40px 0px;
  color: white;
}

/* horizontal footer */

.footer-horizontal {
  flex-direction: row;
  justify-content: space-between;
}

.footer-horizontal .footer__left {
  padding: 0 3%;
  flex-grow: 1;
  /* padding-right: 5%; */
  border-right: 1px solid white;
}

.footer__left img {
  max-width: 95%;
  margin: 15px 0;
}

.footer__left h4 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 0;
  margin-top: 28px;
}

.footer__left h5 {
  margin-top: 20px;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.75);
}

.footer__left p {
  font-size: 14px;
  font-weight: 500;
}

.footer__right {
  flex: 0 0 48%;
}

.footer-horizontal .footer__right {
  /* padding-left: 5%; */
  padding: 0 3%;
  flex-grow: 2;
}

.footer__right img {
  max-width: 95%;
  margin-top: 7px;
}

.footer-horizontal .footer__right-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.footer__right-grid a {
  padding-right: 7px;
}

/* vertical footer */

.footer-vertical {
  flex-direction: column;
  /* height: 100%; */
  padding: 100px 30px 0px;
}

.footer-vertical img {
  /* padding: 0 30px; */
  width: 100%;
}

.footer-vertical .footer__right-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

/* footer right links */

.footer__right-grid {
  margin-bottom: 15px;
}

.footer__right-grid h6 {
  font-size: 15px;
  margin: 28px 0 10px;
}

.footer__right-grid a {
  color: white;
  text-decoration: none;
  display: block;
  font-size: 14px;
  margin: 5px 0;
}

.footer-horizontal .footer__right-contact h6 {
  margin-top: 16px;
}

.footer__right-contact img {
  width: 23px;
  margin-top: 0;
  margin-right: 5px;
}

.footer__right-contact a {
  display: inline;
}
